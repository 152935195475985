import React, { ReactNode, useEffect } from 'react';
import Tab, { TabPropsType } from "./Tab";
import { useLocation, useNavigate } from 'react-router-dom';

interface TabsContainerPropsType {
  children: ReactNode;
  queryParam?: string;
}

const TabsContainer: React.FC<TabsContainerPropsType> = ({
  children,
  queryParam = 'tab',
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getTabFromQuery = (): number => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get(queryParam);
    if (tabParam !== null) {
      const tabIndex = parseInt(tabParam, 10);
      if (!isNaN(tabIndex)) {
        return tabIndex;
      }
    }
    return -1;
  };

  const activeTab = getTabFromQuery();

  const tabs = React.Children.toArray(children) as React.ReactElement<TabPropsType>[];

  useEffect(() => {
    if (activeTab === -1 || activeTab >= tabs.length || activeTab < 0) {
      const params = new URLSearchParams(location.search);
      params.set(queryParam, '0');
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [activeTab, navigate, location.pathname, location.search, queryParam, tabs.length]);

  const validatedTab = getTabFromQuery();
  const validActiveTab = validatedTab >= 0 && validatedTab < tabs.length ? validatedTab : 0;

  const handleTabClick = (index: number) => {
    const params = new URLSearchParams(location.search);
    params.set(queryParam, index.toString());
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap gap-2 rounded-xl">
        {tabs.map((tab, index) =>
          React.isValidElement<TabPropsType>(tab) && tab.type === Tab ? (
            <Tab
              key={index}
              isActive={validActiveTab === index}
              onClick={() => handleTabClick(index)}
              title={tab.props.title}
            />
          ) : null
        )}
      </div>

      <div className="mt-4">
        {tabs[validActiveTab]?.props.children}
      </div>
    </div>
  );
};

export default TabsContainer;
