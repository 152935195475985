import { useState, useEffect } from 'react';
import CreateAppraisalForm from 'features/appraisals/CreateAppraisalForm';
import { getAllAppraisalFormsAPI } from 'api/apiHelper';
import TabsContainer from 'components/TabsContainer';
import Tab from 'components/Tab';
import AssignForms from 'features/appraisals/AssignForms';
import AllFormsTable from 'features/appraisals/AllFormsTable';
import { FormType } from "types/appraisals-types";
import QuestionsPage from 'features/appraisals/QuestionsPage';

const Appraisals = () => {
  const [allForms, setAllForms] = useState<FormType[]>([]);
  const [activeTab, setActiveTab] = useState('all-forms');
  const [selectedStatus, setSelectedStatus] = useState<string>('active');

  const fetchAllForms = async () => {
    try {
      const response = await getAllAppraisalFormsAPI(selectedStatus);
      setAllForms(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllForms();
  }, [selectedStatus]);

  const handleEdit = (id: number) => {
    fetchAllForms();
  };

  const handleDelete = (id: number) => {
    fetchAllForms();
  };

  const handleStatusChange = (id: number, newStatus: boolean) => {
    fetchAllForms();
  };

  return (
    <div className="mt-5">
      <TabsContainer>
        <Tab
          isActive={activeTab === 'all-forms'}
          title="All Forms"
          key="all-forms"
          onClick={() => setActiveTab('all-forms')}
        >
          <AllFormsTable
            title="All Forms"
            tableData={allForms}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            onStatusChange={handleStatusChange}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Tab>
        <Tab
          isActive={activeTab === 'all-forms'}
          title="All Questions"
          key="all-forms"
          onClick={() => setActiveTab('all-forms')}
        >
          <QuestionsPage
          />
        </Tab>
        <Tab
          isActive={activeTab === 'assign-forms'}
          title="Assign Forms"
          key="assign-forms"
          onClick={() => setActiveTab('assign-forms')}
        >
          <AssignForms refreshData={fetchAllForms} />
        </Tab>
      </TabsContainer>
    </div>
  );
};

export default Appraisals;