import { useState, useEffect } from 'react';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import SelectMultipleField from 'components/SelectMultipleField';
import Checkbox from 'components/Checkbox';
import Card from 'components/Card';
import Button from 'components/Button';
import { JobOpeningType } from 'types/careers-types';
import { editJobDetails, getAllSkills, getDepartments } from 'api/apiHelper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type SkillType = {
  id: string;
  name: string;
};

type DepartmentType = {
  id: number;
  name: string;
};

interface EditJobFormPropsType {
  jobDetails: JobOpeningType;
}

const EditJobForm = (props: EditJobFormPropsType) => {
  const { jobDetails } = props;
  const navigate = useNavigate();

  const [formData, setFormData] = useState<JobOpeningType>({
    title: '',
    description: '',
    location: '',
    department: {
      id: '',
      name: ''
    },
    experience_level: 0,
    work_type: '',
    employment_type: '',
    skills_required: [],
    qualifications: '',
    is_active: true,
    positions: 0,
    slug: '',
  });

  const [skillsOptions, setSkillsOptions] = useState<SkillType[]>([]);
  const [departmentsOptions, setDepartmentsOptions] = useState<DepartmentType[]>([]);

  useEffect(() => {
    setFormData(jobDetails);
  }, [jobDetails]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await getAllSkills();
        setSkillsOptions(response.data);
      } catch (error) {
        console.error('Failed to fetch skills', error);
        toast.error('Failed to fetch skills');
      }
    };

    const fetchDepartments = async () => {
      try {
        const response = await getDepartments();
        setDepartmentsOptions(response.data);
      } catch (error) {
        console.error('Failed to fetch departments', error);
        toast.error('Failed to fetch departments');
      }
    };

    fetchSkills();
    fetchDepartments();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = e.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setFormData({
      ...formData,
      [target.id]: value
    });


  };

  const handleSelectChange = (id: string, value: string) => {
    if (id === 'department') {
      const selectedDepartment = departmentsOptions.find(dept => dept.id.toString() === value);
      setFormData({
        ...formData,
        department: selectedDepartment
          ? { id: selectedDepartment.id.toString(), name: selectedDepartment.name }
          : { id: '', name: '' }
      });
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value === "" ? undefined : Number(e.target.value);
    if (number === undefined || number >= 0) {
      setFormData((state) => ({ ...state, [e.target.id]: number }));
    }
  }

  const handleMultiSelectChange = (selectedSkills: SkillType[]) => {
    setFormData({
      ...formData,
      skills_required: selectedSkills
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await editJobDetails(formData, jobDetails.slug);
      toast.success("Job updated successfully");
      handleReset();
      navigate(`/careers/job/${jobDetails.slug}`);
    } catch (error) {
      console.log(error);
      toast.error("Failed to update job");
    }
  };

  const handleReset = () => {
    setFormData({
      title: '',
      description: '',
      location: '',
      department: {
        id: '',
        name: ''
      },
      experience_level: 0,
      work_type: '',
      employment_type: '',
      skills_required: [],
      qualifications: '',
      is_active: true,
      positions: 0,
      slug: '',
    });
  };

  return (
    <Card extra="w-full h-full pb-10 p-4">
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">Update Job</h4>
      </div>

      <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit} onReset={handleReset}>
        <div className="flex flex-col md:flex-row gap-4">
          <InputField
            id="title"
            label="Job Title"
            type="text"
            placeholder="Enter job title"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.title}
          />
          <InputField
            id="location"
            label="Location"
            type="text"
            placeholder="Enter location"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.location}
          />
        </div>
        <InputField
          id="description"
          label="Job Description"
          type="text"
          placeholder="Enter job description"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={formData.description}
        />
        <div className="flex flex-col md:flex-row gap-4">
          <SelectField
            id="department"
            label="Department"
            options={departmentsOptions.map(dept => ({
              value: dept.id.toString(),
              label: dept.name
            }))}
            onChange={e => handleSelectChange('department', e.target.value)}
            extra="w-full"
            value={formData.department?.id}
          />
          <SelectField
            id="work_type"
            label="Work Type"
            options={[
              { value: '', label: 'Select Work Type' },
              { value: 'remote', label: 'Remote' },
              { value: 'hybrid', label: 'Hybrid' },
              { value: 'onsite', label: 'Onsite' }
            ]}
            onChange={handleChange}
            extra="w-full"
            value={formData.work_type}
          />
          <SelectField
            id="employment_type"
            label="Employment Type"
            options={[
              { value: '', label: 'Select Employment Type' },
              { value: 'full-time', label: 'Full-time' },
              { value: 'part-time', label: 'Part-time' },
              { value: 'contract', label: 'Contract' },
              { value: 'internship', label: 'Internship' }
            ]}
            onChange={handleChange}
            extra="w-full"
            value={formData.employment_type}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <InputField
            id="qualifications"
            label="Qualifications"
            type="text"
            placeholder="Enter qualifications"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.qualifications}
          />
          <InputField
            id="experience_level"
            label="Experience Level"
            type='number'
            placeholder="Enter experience level"
            extra="w-full"
            variant="outlined"
            onChange={handleNumberChange}
            value={formData.experience_level !== undefined ? formData.experience_level : ''}

          />
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <InputField
            id="positions"
            label="Positions"
            type="number"
            placeholder="Enter positions"
            extra="w-full"
            variant="outlined"
            onChange={handleNumberChange}
            value={formData.positions !== undefined ? formData.positions : ''}
          />
          <InputField
            id="slug"
            label="Slug"
            type="text"
            placeholder="Enter slug"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.slug}
          />
        </div>
        <SelectMultipleField<SkillType>
          label="Required Skills"
          options={skillsOptions}
          getOptionLabel={(skill) => skill.name}
          getOptionValue={(skill) => skill.id}
          value={formData.skills_required}
          onChange={handleMultiSelectChange}
          extra="w-full"
          required
        />
        <div className="flex flex-row gap-2">
          <Checkbox
            id="is_active"
            onChange={handleChange}
            checked={formData.is_active}
          />
          <label className="text-sm text-navy-700 dark:text-white font-bold">
            Is Active
          </label>
        </div>
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">Cancel</Button>
          <Button type="submit" varient="primary" className="w-max">Submit</Button>
        </div>
      </form>
    </Card>
  );
};

export default EditJobForm;
