import React, { useState } from 'react';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangePickerProps {
  onDateChange?: (startDate: Date, endDate: Date) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ onDateChange }) => {
  const initialRange: Range[] = [
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ];

  const [state, setState] = useState<Range[]>(initialRange);

  const handleSelect = (ranges: RangeKeyDict) => {
    const selection = ranges.selection;
    setState([selection]);
    if (onDateChange) {
      onDateChange(selection.startDate, selection.endDate);
    }
  };

  return (
    <DateRange
      editableDateInputs={true}
      onChange={handleSelect}
      moveRangeOnFirstSelection={false}
      ranges={state}
      rangeColors={['#002060']}
      color='#002060'
    />
  );
};

export default DateRangePicker;
