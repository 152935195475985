import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { getAllQuestionsAPI } from 'api/apiHelper';
import { QuestionType } from 'types/appraisals-types';
import AllQuestionsTable from 'features/appraisals/AllQuestionsTable';
import { toast } from 'react-toastify';
import Button from 'components/Button';

const QuestionsPage: React.FC = () => {
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const res = await getAllQuestionsAPI();
        setQuestions(res.data);
      } catch (error: any) {
        console.error('Failed to fetch questions', error);
        toast.error('Failed to fetch questions.', {
          autoClose: 2000,
        });
      }
    };
    fetchQuestions();
  }, []);

  const handleDelete = (id: number) => {
    setQuestions((prevQuestions) => prevQuestions.filter((q) => q.id !== id));
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Questions</h1>
        <Button varient="primary" onClick={() => navigate('/appraisals/question/create')}>
          Create New Question
        </Button>
      </div>
      <AllQuestionsTable questions={questions} onDelete={handleDelete} />
    </div>
  );
};

export default QuestionsPage;
