import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import { Link } from "react-router-dom";
import Table from "components/Table";
import { FaRegEye } from "react-icons/fa";
import { FormType } from "types/appraisals-types";

interface AllFormsTablePropsType {
  title: string;
  tableData: FormType[] | [];
 }

const AssignedFormTable = (props: AllFormsTablePropsType) => {
  const { title, tableData } = props;

  const columnHelper = createColumnHelper<FormType>(); 
  const columns: ColumnDef<FormType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader> 
      ),
      cell: (info) => (
       <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("title", {
      id: "title",
      header: () => (
        <TableHeader>Title</TableHeader> 
      ),
      cell: (info) => (
       <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <TableHeader>Description</TableHeader> 
      ),
      cell: (info) => (
       <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("id", {
      id: "view",
      header: () => (
        <TableHeader>View</TableHeader>
      ),
      cell: (info) => (
        <Link to={`form/${info.getValue()}`}> 
          <TableCell className="!min-w-0 !pr-0 !w-max">
          <FaRegEye className='h-6 w-6 text-blue-400 hover:text-blue-600 transition' />
          </TableCell>
        </Link>
      ),
    }),
  ];

  return (
    <Table<FormType> tableData={tableData} columns={columns} tableHeading={title} /> 
  );
}

export default AssignedFormTable;
