import React from 'react';
import Button from 'components/Button';

interface SelectAllButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isAllSelected: boolean;
}

const SelectAllButton: React.FC<SelectAllButtonProps> = ({ isAllSelected, onClick, ...rest }) => {
  return (
    <Button varient="primary" type="button" onClick={onClick} {...rest}>
      {isAllSelected ? 'Unselect All' : 'Select All'}
    </Button>
  );
};

export default SelectAllButton;
