import Select, { MultiValue } from "react-select";

interface SelectMultipleFieldProps<T>{
  label?: string;
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string;
  defaultValue?: T[];
  value?: T[];
  onChange?: (selectedOptions: T[]) => void;
  required?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  hideSelectedOptions?: boolean;
  controlShouldRenderValue?: boolean;
  extra?: string;
}

const SelectMultipleField = <T,>(props: SelectMultipleFieldProps<T>) => {
  const {
    label,
    options,
    getOptionLabel,
    getOptionValue,
    defaultValue,
    value,
    onChange,
    isOpen,
    setIsOpen,
    hideSelectedOptions,
    controlShouldRenderValue,
    extra,
  } = props;

  const mappedOptions = options?.map(option => ({
    value: getOptionValue(option),
    label: getOptionLabel(option),
    data: option,
  }));

  const mappedDefaultValue = defaultValue?.map(option => ({
    value: getOptionValue(option),
    label: getOptionLabel(option),
    data: option,
  }));

  const mappedValue = value ? value?.map(option => ({
    value: getOptionValue(option),
    label: getOptionLabel(option),
    data: option,
  })) : mappedDefaultValue;

  const handleChange = (selected: MultiValue<{ value: string; label: string; data: T }>) => {
    const selectedValues = selected.map(option => option.data);
    onChange && onChange(selectedValues);
  }

  return (
    <div>
      <label
        className={`mb-2 mt-6 flex w-full text-sm font-bold text-brand-950 dark:text-white ${
          !label ? "hidden" : "static"
        }`}
      >
        {label}
      </label>
      <Select
        isMulti
        options={mappedOptions}
        defaultValue={mappedDefaultValue}
        onChange={handleChange}
        value={mappedValue}
        placeholder="Select Options"
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen && setIsOpen(true)}
        onMenuClose={() => setIsOpen && setIsOpen(false)}
        classNames={{
          control: ({ isFocused }) => `!overflow-hidden text-md h-12 flex w-full items-center justify-center !rounded-xl !border bg-white/0 !border-gray-200 ${isFocused && '!ring-0'} ${extra}`,
        }}
        controlShouldRenderValue={controlShouldRenderValue ?? true}
        hideSelectedOptions={hideSelectedOptions ?? true}
      />
    </div>
  );
};

export default SelectMultipleField;
