import { useEffect, useState } from "react";
import { JobApplicationType } from "types/careers-types";
import { getAllJobApplications } from "api/apiHelper";
import JobApplicationsTable from "./JobApplicationsTable";

const JobApplicationsTab = () => {
  const [jobApplications, setJobApplications] = useState<JobApplicationType[]>([]);

  useEffect(() => {
    fetchJobApplications()
  }, [])

  const fetchJobApplications = async () => {
    try {
      const response = await getAllJobApplications();
      setJobApplications(response.data);
      console.log(response.data, "ncjdubgecbbrbr")
    } catch (error) {
      console.log(error)
    }
  }

  if (!jobApplications) return <div>Loading...</div>

  return (
    <JobApplicationsTable jobApplications={jobApplications} />
  )
}

export default JobApplicationsTab;
