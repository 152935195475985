import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'components/InputField';
import SelectMultipleField from 'components/SelectMultipleField';
import Card from 'components/Card';
import Button from 'components/Button';
import TextField from 'components/TextField';
import Checkbox from 'components/Checkbox';
import { AppraisalFormCreateType, QuestionType } from 'types/appraisals-types';
import { createAppraisalFormAPI, getAllQuestionsAPI } from 'api/apiHelper';
import { toast } from 'react-toastify';

interface QuestionWithWeightage extends QuestionType {
  weightage?: number;
}

const CreateAppraisalForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionWithWeightage[]>([]);
  const [questionsOptions, setQuestionsOptions] = useState<QuestionType[]>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await getAllQuestionsAPI();
        setQuestionsOptions(response.data);
      } catch (error) {
        console.error('Failed to fetch questions', error);
        toast.error('Failed to fetch questions', {
          autoClose: 2000,
        });
      }
    };
    fetchQuestions();
  }, []);

  const handleQuestionWeightageChange = (questionId: number, newWeightage: string) => {
    setSelectedQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, weightage: newWeightage === '' ? undefined : parseInt(newWeightage) } : q
      )
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData: AppraisalFormCreateType = {
      title,
      description,
      is_active: isActive,
      questions: selectedQuestions.map((q) => ({
        question: q.id!,
        weightage: q.question_type !== 'text' ? q.weightage || 100 : undefined,
      })),
    };

    try {
      const response = await createAppraisalFormAPI(formData);
      const formId = response.data.id;

      toast.success('Form created successfully', {
        autoClose: 2000,
      });

      setTimeout(() => {
        navigate(`/appraisals/form/${formId}`);
      }, 200);

      setTitle('');
      setDescription('');
      setSelectedQuestions([]);
      setIsActive(true);
    } catch (error) {
      console.error(error);
      toast.error('Failed to create form', {
        autoClose: 2000,
      });

      setTimeout(() => {
        navigate("/employee/appraisal?tab=0");
      }, 200);
    }
  };

  const handleReset = () => {
    setTitle('');
    setDescription('');
    setSelectedQuestions([]);
    setIsActive(true);
  };

  return (
    <Card extra="w-full h-full pb-10 p-4 mt-5">
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">Create Appraisal Form</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <InputField
          id="title"
          label="Title"
          type="text"
          placeholder="Enter form title"
          extra="w-full"
          variant="outlined"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          required
        />
        <TextField
          id="description"
          label="Description"
          placeholder="Enter form description"
          extra="w-full mt-4"
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <SelectMultipleField
          label="Select Questions"
          options={questionsOptions}
          getOptionLabel={(option) => option.question_text}
          getOptionValue={(option) => option.id!.toString()}
          value={selectedQuestions}
          onChange={(selectedOptions) => {
            const selected = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
            const updatedSelections: QuestionWithWeightage[] = selected.map((option) => ({
              ...option,
              weightage: option.question_type !== 'text' ? 100 : undefined,
            }));
            setSelectedQuestions(updatedSelections);
          }}
          extra="w-full mt-4"
          required
        />
        {selectedQuestions.map((question) => (
          question.question_type !== 'text' && (
            <div key={question.id} className="mt-2">
              <span className="font-semibold">{question.question_text}</span>
              <InputField
                id={`weightage-${question.id}`}
                label="Weightage"
                type="number"
                placeholder="Enter weightage"
                extra="w-full mt-1"
                variant="outlined"
                onChange={(e) =>
                  handleQuestionWeightageChange(
                    question.id!,
                    e.target.value
                  )
                }
                value={question.weightage !== undefined ? question.weightage.toString() : ''}
                required={question.question_type !== 'text'}
                min={1}
              />
            </div>
          )
        ))}
        <div className="flex items-center mt-4">
          <Checkbox
            id="isActive"
            label="Is Active"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
          <span className="ml-2">{isActive ? 'Active' : 'Inactive'}</span>
        </div>
        <div className="flex justify-end gap-2 mt-6">
          <Button
            type="reset"
            varient="secondary"
            className="w-max"
            onClick={handleReset}
          >
            Cancel
          </Button>
          <Button type="submit" varient="primary" className="w-max">
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default CreateAppraisalForm;