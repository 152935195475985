import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFullAppraisalForm,
  updateAppraisalFormAPI,
  getAllQuestionsAPI,
} from "api/apiHelper";
import { AppraisalFormDetailType, QuestionType } from "types/appraisals-types";
import Card from "components/Card";
import InputField from "components/InputField";
import SelectMultipleField from "components/SelectMultipleField";
import Button from "components/Button";
import { toast } from "react-toastify";
import TextField from "components/TextField";
import Checkbox from "components/Checkbox";

interface QuestionWithWeightage extends QuestionType {
  weightage?: number;
}

const EditAppraisalForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionWithWeightage[]>([]);
  const [allQuestions, setAllQuestions] = useState<QuestionType[]>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchAllQuestions = async () => {
      try {
        const res = await getAllQuestionsAPI();
        setAllQuestions(res.data);
      } catch (error: any) {
        console.error("Failed to fetch all questions", error);
        toast.error("Failed to fetch available questions.", {
          autoClose: 2000,
        });
      }
    };
    fetchAllQuestions();
  }, []);

  const handleQuestionWeightageChange = (questionId: number, newWeightage: number) => {
    setSelectedQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, weightage: newWeightage } : q
      )
    );
  };

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const res = await getFullAppraisalForm(id!);
        const formData: AppraisalFormDetailType = res.data;
        setTitle(formData.title);
        setDescription(formData.description);
        setSelectedQuestions(
          formData.questions.map((fq: any) => ({
            ...fq.question,
            weightage: fq.question.question_type !== 'text' ? fq.weightage : undefined,
          }))
        );
        setIsActive(formData.is_active);
      } catch (error: any) {
        console.error("Failed to fetch form", error);
        toast.error("Failed to fetch form details.", {
          autoClose: 2000,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchForm();
  }, [id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const updatedData = {
        title,
        description,
        is_active: isActive,
        questions: selectedQuestions.map((q) => ({
          question: q.id!,
          weightage: q.question_type !== 'text' ? (q.weightage || 100) : undefined,
        })),
      };

      await updateAppraisalFormAPI(id!, updatedData);
      toast.success("Form updated successfully!", {
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate(`/appraisals/form/${id}`);
      }, 200);
    } catch (error: any) {
      console.error("Failed to update form", error);
      const errorMessage =
        error.response?.data?.detail || "Failed to update form.";
      toast.error(errorMessage, {
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/employee/appraisal?tab=0");
      }, 200);
    } finally {
      setUpdating(false);
    }
  };

  const handleReset = () => {
    setTitle('');
    setDescription('');
    setSelectedQuestions([]);
    setIsActive(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card extra="w-full p-5 mt-5">
      <h2 className="text-2xl font-bold mb-4">Edit Appraisal Form</h2>
      <form onSubmit={handleSubmit}>
        <InputField
          id="title"
          label="Title"
          type="text"
          placeholder="Enter form title"
          extra="w-full"
          variant="outlined"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          required
        />

        <TextField
          id="description"
          label="Description"
          placeholder="Enter form description"
          extra="w-full mt-4"
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <SelectMultipleField
          label="Select Questions"
          options={allQuestions}
          getOptionLabel={(option) => option.question_text}
          getOptionValue={(option) => option.id!.toString()}
          value={selectedQuestions}
          onChange={(selectedOptions) => {
            const selected = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
            const updatedSelections: QuestionWithWeightage[] = selected.map((option) => {
              const existingQuestion = selectedQuestions.find((q) => q.id === option.id);
              return {
                ...option,
                weightage: option.question_type !== 'text' ? (existingQuestion ? existingQuestion.weightage : 100) : undefined,
              };
            });
            setSelectedQuestions(updatedSelections);
          }}
          extra="w-full mt-4"
          required
        />

        {selectedQuestions.map((question) => (
          question.question_type !== 'text' && (
            <div key={question.id} className="mt-2">
              <span className="font-semibold">{question.question_text}</span>
              <InputField
                id={`weightage-${question.id}`}
                label="Weightage"
                type="number"
                placeholder="Enter weightage"
                extra="w-full mt-1"
                variant="outlined"
                onChange={(e) => handleQuestionWeightageChange(question.id!, parseInt(e.target.value))}
                value={question.weightage !== undefined ? question.weightage.toString() : ''}
                required={question.question_type !== 'text'}
                min={1}
              />
            </div>
          )
        ))}

        <div className="flex items-center mt-4">
          <Checkbox
            id="isActive"
            label="Is Active"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
          <span className="ml-2">{isActive ? 'Active' : 'Inactive'}</span>
        </div>

        <div className="flex justify-end gap-2 mt-6">
          <Button
            type="button"
            varient="secondary"
            className="w-max"
            onClick={() => navigate("/employee/appraisal?tab=0")}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            varient="primary"
            className="w-max"
            disabled={updating}
          >
            {updating ? "Updating..." : "Update Form"}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default EditAppraisalForm;
