import { useLocation, Navigate } from "react-router-dom";
import { getLocalStorageTokens } from "utils/utilityFunctions";
import { useAppSelector, usePermissions } from "app/hooks";
import { useEffect } from "react";

interface RequireAuthProps {
  allowedPermissions: UserPermissionsType;
  children: React.ReactNode;
  title: string;
}

const RequireAuth = (props: RequireAuthProps) => {
  const { allowedPermissions, children, title } = props;
  
  const location = useLocation();
  const { accessToken } = useAppSelector((state) => ({
    accessToken: state.auth.accessToken,
  }));
  
  const userPermissions: UserPermissionType[] = usePermissions() || [];
  const token: string = accessToken || getLocalStorageTokens() || "";

  const isAuthorized = allowedPermissions.some((perm) =>
    userPermissions.includes(perm)
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  if (!token) {
    return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
  } else if (!isAuthorized && location.pathname !== "/bio-data") {
    return <Navigate to="/forbidden" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default RequireAuth;
