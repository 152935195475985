import React, { useState } from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import TableHeader from 'components/TableHeader';
import TableCell from 'components/TableCell';
import { Link, useNavigate } from 'react-router-dom';
import Table from 'components/Table';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { FaRegEye, FaCheck, FaTimes } from 'react-icons/fa';
import { MdEdit, MdDelete, MdEditOff } from 'react-icons/md';
import { FormType } from 'types/appraisals-types';
import { deleteAppraisalFormAPI, setAppraisalFormStatusAPI } from 'api/apiHelper';
import { toast } from 'react-toastify';
import ConfirmationModal from 'features/appraisals/ConfirmationModal';
import SelectField from 'components/SelectField';
import Button from 'components/Button';

interface AllFormsTablePropsType {
  title: string;
  tableData: FormType[];
  selectedStatus: string;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onStatusChange: (id: number, newStatus: boolean) => void;
}

const STATUS_FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const AllFormsTable: React.FC<AllFormsTablePropsType> = (props) => {
  const { title, tableData, selectedStatus, setSelectedStatus, onDelete, onStatusChange } = props;
  const columnHelper = createColumnHelper<FormType>();
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteSelectedId, setDeleteSelectedId] = useState<number | null>(null);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [statusSelectedId, setStatusSelectedId] = useState<number | null>(null);
  const [currentStatus, setCurrentStatus] = useState<boolean>(false);

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const openDeleteModal = (id: number) => {
    setDeleteSelectedId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteSelectedId(null);
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteSelectedId === null) return;

    try {
      await deleteAppraisalFormAPI(deleteSelectedId);
      toast.success('Form deleted successfully!');
      onDelete(deleteSelectedId);
    } catch (error: any) {
      console.error('Failed to delete form', error);
      const errorMessage = error.response?.data?.detail || 'Failed to delete form.';
      toast.error(errorMessage);
    } finally {
      closeDeleteModal();
    }
  };

  const openStatusModal = (id: number, isActive: boolean) => {
    setStatusSelectedId(id);
    setCurrentStatus(isActive);
    setIsStatusModalOpen(true);
  };

  const closeStatusModal = () => {
    setStatusSelectedId(null);
    setCurrentStatus(false);
    setIsStatusModalOpen(false);
  };

  const handleConfirmStatusChange = async () => {
    if (statusSelectedId === null) return;

    const newStatus = !currentStatus;

    try {
      await setAppraisalFormStatusAPI(statusSelectedId, newStatus);
      toast.success(`Form has been ${newStatus ? 'activated' : 'deactivated'} successfully!`);
      onStatusChange(statusSelectedId, newStatus);
    } catch (error: any) {
      console.error('Failed to update form status', error);
      const errorMessage = error.response?.data?.detail || 'Failed to update form status.';
      toast.error(errorMessage);
    } finally {
      closeStatusModal();
    }
  };

  const columns: ColumnDef<FormType, any>[] = [
    columnHelper.accessor('id', {
      header: () => <TableHeader>ID</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor('title', {
      header: () => <TableHeader>Title</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.display({
      id: 'edit',
      header: () => <TableHeader className='!w-16'>Edit</TableHeader>,
      cell: (info) => (
        <TableCell className='!min-w-0 !pr-0 !w-max'>
          {info.row.original.is_assigned ? (
            <MdEditOff className='h-6 w-6 text-green-200' />
          ) : (
            <MdEdit
              className='h-6 w-6 text-green-400 cursor-pointer hover:text-green-600 transition'
              onClick={() => navigate(`/appraisals/form/edit/${info.row.original.id}`)}
              aria-label="Edit Form"
            />
          )}
        </TableCell>
      ),
    }),
    columnHelper.display({
      id: 'delete',
      header: () => <TableHeader className='!w-16'>Delete</TableHeader>,
      cell: (info) => (
        <TableCell className='!min-w-0 !pr-0 !w-max'>
          {info.row.original.is_assigned ? (
            <RiDeleteBin2Fill className='h-6 w-6 text-red-200' />
          ) : (
            <MdDelete
              className='h-6 w-6 text-red-400 cursor-pointer hover:text-red-600 transition'
              onClick={() => openDeleteModal(info.row.original.id)}
              aria-label="Delete Form"
            />
          )}
        </TableCell>
      ),
    }),
    columnHelper.display({
      id: 'status',
      header: () => <TableHeader className='!w-16'>Status</TableHeader>,
      cell: (info) => (
        <TableCell className='!min-w-0 !pr-0 !w-max'>
          {info.row.original.is_active ? (
            <FaCheck
              className='h-6 w-6 text-green-400 cursor-pointer hover:text-green-600 transition'
              onClick={() => openStatusModal(info.row.original.id, info.row.original.is_active)}
              title='Click to deactivate'
              aria-label="Deactivate Form"
            />
          ) : (
            <FaTimes
              className='h-6 w-6 text-red-400 cursor-pointer hover:text-red-600 transition'
              onClick={() => openStatusModal(info.row.original.id, info.row.original.is_active)}
              title='Click to activate'
              aria-label="Activate Form"
            />
          )}
        </TableCell>
      ),
    }),
    columnHelper.display({
      id: 'view',
      header: () => <TableHeader>View</TableHeader>,
      cell: (info) => (
        <TableCell className='!min-w-0 !pr-0 !w-max'>
          <Link to={`/appraisals/form/${info.row.original.id}`} aria-label="View Form">
            <FaRegEye className='h-6 w-6 text-blue-400 hover:text-blue-600 transition' />
          </Link>
        </TableCell>
      ),
    }),
  ];

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">{title}</h1>
        <Button varient="primary" onClick={() => navigate('/appraisals/form/create')}>
          Create New Form
        </Button>
      </div>
      <Table<FormType> tableData={tableData} columns={columns} tableHeading={title}>
        <div className='flex justify-end items-center space-x-4 mb-4'>
          <SelectField
            options={STATUS_FILTER_OPTIONS}
            value={selectedStatus}
            onChange={handleStatusChange}
            extra='!w-full !max-w-xs'
            aria-label="Filter by Status"
          />
        </div>
      </Table>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title='Confirm Deletion'
        message='Are you sure you want to delete this form?'
        onConfirm={handleConfirmDelete}
        onCancel={closeDeleteModal}
      />

      <ConfirmationModal
        isOpen={isStatusModalOpen}
        title='Confirm Status Change'
        message={
          currentStatus
            ? 'Do you want to make this form inactive?'
            : 'Do you want to make this form active?'
        }
        onConfirm={handleConfirmStatusChange}
        onCancel={closeStatusModal}
      />
    </>
  );
};

export default AllFormsTable;
