import React, { useEffect, useState } from 'react';
import { getResponseByIdAPI } from 'api/apiHelper';
import { AppraisalResponseType, AnswerType } from 'types/appraisals-types';
import { useParams } from 'react-router-dom';
import Card from 'components/Card';

const ResponseDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [response, setResponse] = useState<AppraisalResponseType | null>(null);

  useEffect(() => {
    const fetchResponse = async () => {
      try {
        const res = await getResponseByIdAPI(id);
        setResponse(res.data);
      } catch (error) {
        console.error('Failed to fetch response', error);
      }
    };
    fetchResponse();
  }, [id]);

  if (!response) {
    return <div>Loading...</div>;
  }

  const submittedAtDate = new Date(response.submitted_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Transform answers object to array
  const answersArray: AnswerType[] = response.answers
    ? Object.values(response.answers)
    : [];

  return (
    <Card extra="w-full h-full px-3 py-5">
      <div className="mb-8 flex w-full items-center justify-between">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">Response Details</h4>
      </div>

      <div className="grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Form Title</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">{response.form.title}</p>
        </div>

        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Employee</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {response.employee.first_name} {response.employee.last_name}
          </p>
        </div>

        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Submitted At</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">{submittedAtDate}</p>
        </div>
      </div>

      <h4 className="px-2 mt-6 text-xl font-bold text-navy-700 dark:text-white">Answers</h4>
      {answersArray.length > 0 ? (
        answersArray.map((answer) => (
          <div key={answer.id} className="mt-4 grid grid-cols-1 gap-4 px-2 sm:grid-cols-2">
            <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="mb-2 text-sm text-gray-600">Question</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {answer.question?.question_text || 'Question not available'}
              </p>
            </div>

            {answer.text_answer && (
              <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="mb-2 text-sm text-gray-600">Answer</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {answer.text_answer}
                </p>
              </div>
            )}
            {answer.selected_options && answer.selected_options.length > 0 && (
              <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="mb-2 text-sm text-gray-600">Selected Options</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {answer.selected_options.map((option) => option.option_text).join(', ')}
                </p>
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="px-2 text-base font-medium text-navy-700 dark:text-white">No answers available.</p>
      )}
    </Card>
  );
};

export default ResponseDetail;