// components/AssignedEmployeesTable.tsx

import React from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import TableHeader from 'components/TableHeader';
import TableCell from 'components/TableCell';
import Table from 'components/Table';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { AssignedToType } from 'types/appraisals-types';

interface AssignedEmployeesTableProps {
  title: string;
  tableData: AssignedToType[];
}

const AssignedEmployeesTable: React.FC<AssignedEmployeesTableProps> = ({ title, tableData }) => {
  const columnHelper = createColumnHelper<AssignedToType>();

  const columns: ColumnDef<AssignedToType, any>[] = [
    columnHelper.accessor('employee.id', {
      header: () => <TableHeader>ID</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor(row => `${row.employee.first_name} ${row.employee.last_name}`, {
      id: 'employeeName',
      header: () => <TableHeader>Employee Name</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor('status', {
      header: () => <TableHeader>Status</TableHeader>,
      cell: (info) => (
        <TableCell>
          <span
            className={`px-2 py-1 rounded ${
              info.getValue() === 'completed' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'
            }`}
          >
            {info.getValue()?.charAt(0).toUpperCase() + info.getValue()?.slice(1)}
          </span>
        </TableCell>
      ),
    }),
    columnHelper.accessor('response_id', {
      id: 'response_id',
      header: () => <TableHeader>View</TableHeader>,
      cell: (info) => {
        const row = tableData.find(item => item.response_id === info.getValue());
        return row.status === 'completed' ? (
          <Link to={`/employee/appraisal/response/${info.getValue()}`}>
            <TableCell className="!min-w-0 !pr-0 !w-max">
              <FaRegEye className="h-6 w-6 text-blue-400" />
            </TableCell>
          </Link>
        ) : (
          <TableCell className="!min-w-0 !pr-0 !w-max">
            <FaRegEyeSlash className="h-6 w-6 text-blue-200" />
          </TableCell>
        );
      },
    }),
  ];

  return (
    <div className="mt-8">
      <Table<AssignedToType> tableData={tableData} columns={columns} tableHeading={title} />
    </div>
  );
};

export default AssignedEmployeesTable;
