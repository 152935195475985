import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFullAppraisalForm } from "api/apiHelper";
import AppraisalFormComponent from "features/appraisals/AppraisalFormComponent";
import { FullFormType } from "types/appraisals-types";


const AppraisalForm = () => {
  const { appraisalId } = useParams<{ appraisalId: string }>();
  const [form, setForm] = useState<FullFormType | null>(null);
  const navigate = useNavigate();

  const fetchForm = async (id: string) => {
    try {
      const response = await getFullAppraisalForm(id);
      setForm(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!appraisalId || !appraisalId.match(/^\d+$/)) {
      navigate('/not-found');
      return;
    }

    fetchForm(appraisalId);
  }, [appraisalId, navigate]);

  return (
    <div className="mt-5">
      <AppraisalFormComponent formData={form} />
    </div>
  );
};

export default AppraisalForm;
