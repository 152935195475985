import React, { useState } from 'react';
import { QuestionOptionType } from 'types/appraisals-types';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { MdDelete } from 'react-icons/md';

interface OptionsListProps {
  options: QuestionOptionType[];
  onOptionsChange: (options: QuestionOptionType[]) => void;
}

const OptionsList: React.FC<OptionsListProps> = ({ options, onOptionsChange }) => {
  const [localOptions, setLocalOptions] = useState<QuestionOptionType[]>(options);

  const handleOptionChange = (index: number, field: keyof QuestionOptionType, value: any) => {
    const updatedOptions = [...localOptions];
    updatedOptions[index] = { ...updatedOptions[index], [field]: value };
    setLocalOptions(updatedOptions);
    onOptionsChange(updatedOptions);
  };

  const addOption = () => {
    const newOption: QuestionOptionType = { option_text: '', rating: 0 };
    setLocalOptions([...localOptions, newOption]);
  };

  const removeOption = (index: number) => {
    const updatedOptions = localOptions.filter((_, i) => i !== index);
    setLocalOptions(updatedOptions);
    onOptionsChange(updatedOptions);
  };

  return (
    <div className="mt-4">
      <h3 className="text-lg font-semibold mb-2">Options</h3>
      {localOptions.map((option, index) => (
        <div key={index} className="flex items-center space-x-2 mb-2">
          <InputField
            id={`option_text_${index}`}
            label={`Option ${index + 1}`}
            type="text"
            placeholder="Option text"
            extra="w-full"
            variant="outlined"
            onChange={(e) => handleOptionChange(index, 'option_text', e.target.value)}
            value={option.option_text}
            required
          />
          <InputField
            id={`rating_${index}`}
            label="Rating"
            type="number"
            placeholder="Rating"
            extra="w-32"
            variant="outlined"
            onChange={(e) => handleOptionChange(index, 'rating', parseInt(e.target.value))}
            value={option.rating}
            required
          />
          <MdDelete className="h-6 w-6 text-red-400 cursor-pointer" onClick={() => removeOption(index)} />
        </div>
      ))}
      <Button type="button" varient="secondary" onClick={addOption}>
        Add Option
      </Button>
    </div>
  );
};

export default OptionsList;
