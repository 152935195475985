// EditQuestion.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getQuestionByIdAPI, updateQuestionAPI } from 'api/apiHelper';
import { QuestionType } from 'types/appraisals-types';
import Card from 'components/Card';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import OptionsList from 'features/appraisals/OptionsList';

const QUESTION_TYPES = [
  { label: 'Text', value: 'text' },
  { label: 'Rating', value: 'rating' },
  { label: 'Select', value: 'select' },
  { label: 'Multi-Select', value: 'multi-select' },
];

const EditQuestion: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [questionData, setQuestionData] = useState<QuestionType | null>(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const res = await getQuestionByIdAPI(id!);
        setQuestionData(res.data);
      } catch (error: any) {
        console.error('Failed to fetch question', error);
        toast.error('Failed to fetch question details.', {
          autoClose: 2000,
        });
        navigate('/questions');
      } finally {
        setLoading(false);
      }
    };
    fetchQuestion();
  }, [id, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setUpdating(true);
    try {
      await updateQuestionAPI(id!, questionData);
      toast.success('Question updated successfully!', {
        autoClose: 2000,
      });
      navigate('/employee/appraisal?tab=1');
    } catch (error: any) {
      console.error('Failed to update question', error);
      const errorMessage = error.response?.data?.detail || 'Failed to update question.';
      toast.error(errorMessage, {
        autoClose: 2000,
      });
    } finally {
      setUpdating(false);
    }
  };

  if (loading || !questionData) {
    return <div>Loading...</div>;
  }

  return (
    <Card extra="w-full p-5 mt-5">
      <h2 className="text-2xl font-bold mb-4">Edit Question</h2>
      <form onSubmit={handleSubmit}>
        <InputField
          id="question_text"
          label="Question Text"
          type="text"
          placeholder="Enter question text"
          extra="w-full"
          variant="outlined"
          onChange={(e) => setQuestionData({ ...questionData, question_text: e.target.value })}
          value={questionData.question_text}
          required
        />

        <SelectField
          label="Question Type"
          options={QUESTION_TYPES}
          value={questionData.question_type}
          onChange={(e) => setQuestionData({ ...questionData, question_type: e.target.value })}
          extra="w-full mt-4"
          required
        />

        {['select', 'multi-select', 'rating'].includes(questionData.question_type) && (
          <OptionsList
            options={questionData.options || []}
            onOptionsChange={(options) => setQuestionData({ ...questionData, options })}
          />
        )}

        <div className="flex justify-end gap-2 mt-6">
          <Button type="button" varient="secondary" className="w-max" onClick={() => navigate('/questions')}>
            Cancel
          </Button>
          <Button type="submit" varient="primary" className="w-max" disabled={updating}>
            {updating ? 'Updating...' : 'Update Question'}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default EditQuestion;
