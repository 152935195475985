import React, { useState, useEffect } from 'react';
import { getAllJobOpenings } from "api/apiHelper";
import { JobOpeningType } from "types/careers-types";
import JobsTable from './JobsTable';

const JobsTab = () => {
  const [jobs, setJobs] = useState<JobOpeningType[]>([]);

  useEffect(() => {
    fetchJobOpenings()
  }, [])

  const fetchJobOpenings = async () => {
    try {
      const response = await getAllJobOpenings();
      setJobs(response.data);
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <JobsTable jobs={jobs} />
  )
}

export default JobsTab;
