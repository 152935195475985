import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { JobApplicationType, JobApplicationStatusChoicesType } from 'types/careers-types';
import { getJobDetails } from 'api/apiHelper';
import JobApplicationCard from 'features/carrers/JobApplicationCard';
import { getJobApplicationDetails, getAllJobApplicationsStatus } from 'api/apiHelper';

const JobApplication = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [applicationDetails, setApplicationDetails] = useState<JobApplicationType | null>(null);
  const [availableStatuses, setAvailableStatuses] = useState<JobApplicationStatusChoicesType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchApplicationDetails(jobId);
    fetchAvailableStatuses();
  }, [jobId]);

  const fetchAvailableStatuses = async () => {
    try {
      const response = await getAllJobApplicationsStatus();
      setAvailableStatuses(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApplicationDetails = async (id: string) => {
    try {
      const response = await getJobApplicationDetails(id);
      setApplicationDetails(response.data); 
      setIsLoading(false);
    } catch (err) {
      console.error('Failed to fetch job details:', err);
      setError('Failed to load the job details.');
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!applicationDetails) return <div>No Application details available.</div>;

  return (
    <div className="mt-5">
      <JobApplicationCard applicationDetails={applicationDetails} availableStatuses={availableStatuses} />
    </div>
  );
};

export default JobApplication;