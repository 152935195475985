import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import Card from 'components/Card';
import { QuestionType, QuestionOptionType } from 'types/appraisals-types';
import { createQuestionAPI } from 'api/apiHelper';
import { toast } from 'react-toastify';

const questionTypeOptions = [
  { value: 'text', label: 'Text' },
  { value: 'rating', label: 'Rating' },
  { value: 'select', label: 'Select' },
  { value: 'multi-select', label: 'Multi-Select' },
];

const CreateQuestion = () => {
  const [question, setQuestion] = useState<QuestionType>({
    question_text: '',
    question_type: 'text',
    options: [],
  });
  const navigate = useNavigate();

  const handleQuestionChange = (field: keyof QuestionType, value: any) => {
    setQuestion((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddOption = () => {
    setQuestion((prev) => ({
      ...prev,
      options: [
        ...(prev.options || []),
        {
          option_text: '',
          rating: 0,
        } as QuestionOptionType,
      ],
    }));
  };

  const handleOptionChange = (index: number, field: keyof QuestionOptionType, value: any) => {
    const updatedOptions = [...(question.options || [])];
    updatedOptions[index] = {
      ...updatedOptions[index],
      [field]: value,
    };
    setQuestion((prev) => ({
      ...prev,
      options: updatedOptions,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const payload = { ...question };
      if (!['select', 'multi-select', 'rating'].includes(question.question_type)) {
        delete payload.options;
      }

      await createQuestionAPI(payload);
      toast.success('Question created successfully');
      navigate('/employee/appraisal?tab=1');
    } catch (error) {
      console.error(error);
      toast.error('Failed to create question');
    }
  };

  return (
    <Card extra="w-full h-full pb-10 p-4 mt-5">
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">Create Question</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <InputField
          id="question_text"
          label="Question Text"
          type="text"
          placeholder="Enter question text"
          extra="w-full"
          variant="outlined"
          onChange={(e) => handleQuestionChange('question_text', e.target.value)}
          value={question.question_text}
          required
        />
        <SelectField
          id="question_type"
          label="Question Type"
          options={questionTypeOptions}
          onChange={(e) => handleQuestionChange('question_type', e.target.value)}
          value={question.question_type}
          extra="w-full mt-2"
          required
        />
        {['select', 'multi-select', 'rating'].includes(question.question_type) && (
          <div className="mt-4">
            <h6 className="font-medium">Options</h6>
            {question.options?.map((option, index) => (
              <div key={index} className="flex items-center mt-2">
                <InputField
                  id={`option_text_${index}`}
                  label="Option Text"
                  type="text"
                  placeholder="Enter option text"
                  extra="w-full"
                  variant="outlined"
                  onChange={(e) => handleOptionChange(index, 'option_text', e.target.value)}
                  value={option.option_text}
                  required
                />
                <InputField
                  id={`option_rating_${index}`}
                  label="Rating"
                  type="number"
                  placeholder="Enter rating"
                  extra="w-1/3 ml-2"
                  variant="outlined"
                  onChange={(e) => handleOptionChange(index, 'rating', parseInt(e.target.value))}
                  value={option.rating}
                  required
                />
              </div>
            ))}
            <Button type="button" varient="secondary" className="mt-2" onClick={handleAddOption}>
              Add Option
            </Button>
          </div>
        )}
        <div className="flex justify-end gap-2 mt-6">
          <Button
            type="reset"
            varient="secondary"
            className="w-max"
            onClick={() => {
              setQuestion({
                question_text: '',
                question_type: 'text',
                options: [],
              });
            }}
          >
            Cancel
          </Button>
          <Button type="submit" varient="primary" className="w-max">
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default CreateQuestion;
