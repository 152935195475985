import React, { useState } from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import TableHeader from 'components/TableHeader';
import TableCell from 'components/TableCell';
import { useNavigate } from 'react-router-dom';
import Table from 'components/Table';
import { MdEdit, MdDelete, MdEditOff } from 'react-icons/md';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { QuestionType } from 'types/appraisals-types';
import { deleteQuestionAPI } from 'api/apiHelper';
import { toast } from 'react-toastify';
import ConfirmationModal from "features/appraisals/ConfirmationModal";
import { FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface AllQuestionsTableProps {
  questions: QuestionType[];
  onDelete: (id: number) => void;
}

const AllQuestionsTable: React.FC<AllQuestionsTableProps> = ({ questions, onDelete }) => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<QuestionType>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteSelectedId, setDeleteSelectedId] = useState<number | null>(null);

  const openDeleteModal = (id: number) => {
    setDeleteSelectedId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteSelectedId(null);
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteSelectedId === null) return;

    try {
      await deleteQuestionAPI(deleteSelectedId);
      toast.success('Question deleted successfully!');
      onDelete(deleteSelectedId);
    } catch (error: any) {
      console.error('Failed to delete question', error);
      const errorMessage = error.response?.data?.detail || 'Failed to delete question.';
      toast.error(errorMessage);
    } finally {
      closeDeleteModal();
    }
  };

  const columns: ColumnDef<QuestionType, any>[] = [
    columnHelper.accessor('id', {
      header: () => <TableHeader>ID</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor('question_text', {
      header: () => <TableHeader>Question Text</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor('question_type', {
      header: () => <TableHeader>Type</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.display({
      id: 'edit',
      header: () => <TableHeader>Edit</TableHeader>,
      cell: (info) => (
        <TableCell>
          {info.row.original.is_assigned ? (
            <MdEditOff className="h-6 w-6 text-green-200" />
          ) : (
            <MdEdit
              className="h-6 w-6 text-green-400 cursor-pointer"
              onClick={() => navigate(`/question/edit/${info.row.original.id}`)}
            />
          )}
        </TableCell>
      ),
    }),
    columnHelper.display({
      id: 'delete',
      header: () => <TableHeader>Delete</TableHeader>,
      cell: (info) => (
        <TableCell>
          {info.row.original.is_assigned ? (
            <RiDeleteBin2Fill className="h-6 w-6 text-red-200" />
          ) : (
            <MdDelete
              className="h-6 w-6 text-red-400 cursor-pointer hover:text-red-600 transition"
              onClick={() => openDeleteModal(info.row.original.id)}
            />
          )}
        </TableCell>
      ),
    }),
    columnHelper.display({
      id: 'view',
      header: () => <TableHeader>View</TableHeader>,
      cell: (info) => (
        <TableCell className='!min-w-0 !pr-0 !w-max'>
          <Link to={`/appraisals/question/${info.row.original.id}`}>
            <FaRegEye className='h-6 w-6 text-blue-400' />
          </Link>
        </TableCell>
      ),
    }),
  ];

  return (
    <>
      <Table<QuestionType> tableData={questions} columns={columns} tableHeading="All Questions" />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Confirm Deletion"
        message="Are you sure you want to delete this question?"
        onConfirm={handleConfirmDelete}
        onCancel={closeDeleteModal}
      />
    </>
  );
};

export default AllQuestionsTable;
