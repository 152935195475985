import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { JobOpeningType } from 'types/careers-types';
import { getJobDetails } from 'api/apiHelper';
import JobDescriptionCard from '../../features/carrers/JobDescriptionCard';

const JobDetails = () => {
  const { slug } = useParams<{ slug: string }>();
  const [jobDetails, setJobDetails] = useState<JobOpeningType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (slug) {
      fetchJobDetails(slug);
    }
  }, [slug]);

  const fetchJobDetails = async (id: string) => {
    try {
      const response = await getJobDetails(slug);
      const fetchedJob = response.data; 
      setJobDetails(fetchedJob); 
      setIsLoading(false);
    } catch (err) {
      console.error('Failed to fetch job details:', err);
      setError('Failed to load the job details.');
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!jobDetails) return <div>No job details available.</div>;

  return (
    <div className="mt-5">
      <JobDescriptionCard jobDetails={jobDetails} />
    </div>
  );
};

export default JobDetails;
