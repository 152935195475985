import React, { useEffect, useState } from 'react';
import {
  getAllEmployees,
  getAllAppraisalFormsAPI,
  assignFormsToEmployeesAPI,
} from 'api/apiHelper';
import { FormType } from 'types/appraisals-types';
import { EmployeeDataType } from 'types/employee-types';
import Card from 'components/Card';
import { toast } from 'react-toastify';
import Checkbox from 'components/Checkbox';
import SelectAllButton from 'components/SelectAllButton';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';

interface AssignFormsProps {
  refreshData: () => void;
}

const AssignForms = (props: AssignFormsProps) => {
  const { refreshData } = props;
  const [forms, setForms] = useState<FormType[]>([]);
  const [employees, setEmployees] = useState<EmployeeDataType[]>([]);
  const [selectedFormIds, setSelectedFormIds] = useState<number[]>([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([]);
  const [selectAllForms, setSelectAllForms] = useState(false);
  const [selectAllEmployees, setSelectAllEmployees] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchForms();
    fetchEmployees();
  }, []);

  const fetchForms = async () => {
    try {
      const response = await getAllAppraisalFormsAPI();
      setForms(response.data);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch forms.');
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await getAllEmployees();
      setEmployees(response.data);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch employees.');
    }
  };

  const handleFormCheckboxChange = (formId: number) => {
    setSelectedFormIds((prevSelectedFormIds) => {
      if (prevSelectedFormIds.includes(formId)) {
        return prevSelectedFormIds.filter((id) => id !== formId);
      } else {
        return [...prevSelectedFormIds, formId];
      }
    });
  };

  const handleEmployeeCheckboxChange = (employeeId: number) => {
    setSelectedEmployeeIds((prevSelectedEmployeeIds) => {
      if (prevSelectedEmployeeIds.includes(employeeId)) {
        return prevSelectedEmployeeIds.filter((id) => id !== employeeId);
      } else {
        return [...prevSelectedEmployeeIds, employeeId];
      }
    });
  };

  const handleSelectAllForms = () => {
    if (selectAllForms) {
      setSelectedFormIds([]);
    } else {
      setSelectedFormIds(forms.map((form) => form.id));
    }
    setSelectAllForms(!selectAllForms);
  };

  const handleSelectAllEmployees = () => {
    if (selectAllEmployees) {
      setSelectedEmployeeIds([]);
    } else {
      setSelectedEmployeeIds(employees.map((employee) => Number(employee.id)));
    }
    setSelectAllEmployees(!selectAllEmployees);
  };

  useEffect(() => {
    if (selectedFormIds.length === forms.length && forms.length > 0) {
      setSelectAllForms(true);
    } else {
      setSelectAllForms(false);
    }
  }, [selectedFormIds, forms]);

  useEffect(() => {
    if (selectedEmployeeIds.length === employees.length && employees.length > 0) {
      setSelectAllEmployees(true);
    } else {
      setSelectAllEmployees(false);
    }
  }, [selectedEmployeeIds, employees]);

  const handleAssign = async () => {
    if (selectedFormIds.length === 0 || selectedEmployeeIds.length === 0) {
      toast.warning('Please select at least one form and one employee.');
      return;
    }
    try {
      const data = {
        forms: selectedFormIds,
        employees: selectedEmployeeIds,
      };
      await assignFormsToEmployeesAPI(data);
      toast.success('Forms assigned successfully!', {
        autoClose: 2000,
      });
      refreshData();
      handleReset();
      navigate('/employee/appraisal?tab=0');
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail, {
          autoClose: 5000,
        });
      } else {
        toast.error('Failed to assign forms.', {
          autoClose: 2000,
        });
      }
    }
  };

  const handleReset = () => {
    setSelectedFormIds([]);
    setSelectedEmployeeIds([]);
    setSelectAllForms(false);
    setSelectAllEmployees(false);
  };

  return (
    <Card extra="w-full h-full pb-10 p-4">
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Assign Forms to Employees
        </h4>
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center">
          <label className="mt-10 text-sm font-bold text-gray-600 dark:text-white">
            Select Forms
          </label>
          <SelectAllButton isAllSelected={selectAllForms} onClick={handleSelectAllForms} />
        </div>
        <hr className="mt-2 mb-3" />
        <div className="border-gray-300 dark:border-gray-700 rounded p-2 max-h-60 overflow-y-auto">
          {forms.map((form) => (
            <div key={form.id} className="flex items-center mb-2">
              <Checkbox
                checked={selectedFormIds.includes(form.id)}
                onChange={() => handleFormCheckboxChange(form.id)}
                extra="mr-2"
              />
              <label className="text-sm font-medium text-navy-700 dark:text-white">
                {form.title}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center">
          <label className="mt-10 text-sm font-bold text-gray-600 dark:text-white">
            Select Employees
          </label>
          <SelectAllButton isAllSelected={selectAllEmployees} onClick={handleSelectAllEmployees} />
        </div>
        <hr className="mt-2 mb-3" />
        <div className="border-gray-300 dark:border-gray-700 rounded p-2 max-h-60 overflow-y-auto">
          {employees.map((employee) => (
            <div key={employee.id} className="flex items-center mb-2">
              <Checkbox
                checked={selectedEmployeeIds.includes(Number(employee.id))}
                onChange={() => handleEmployeeCheckboxChange(Number(employee.id))}
                extra="mr-2"
              />
              <label className="text-sm font-medium text-navy-700 dark:text-white">
                {employee.first_name} {employee.last_name}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end gap-2 mt-6">
        <Button
          type="reset"
          varient="secondary"
          className="w-max"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          type="button"
          varient="primary"
          className="w-max"
          onClick={handleAssign}
        >
          Assign Forms
        </Button>
      </div>
    </Card>
  );
};

export default AssignForms;
