// features/appraisals/QuestionDetail.tsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import { getQuestionByIdAPI } from "api/apiHelper";
import { toast } from "react-toastify";
import { QuestionType } from "types/appraisals-types";

const QuestionDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [question, setQuestion] = useState<QuestionType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        if (!id) {
          throw new Error("Question ID is missing");
        }
        const res = await getQuestionByIdAPI(Number(id));
        setQuestion(res.data);
      } catch (error: any) {
        console.error("Failed to fetch question", error);
        toast.error("Failed to fetch question.");
      } finally {
        setLoading(false);
      }
    };
    fetchQuestion();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!question) {
    return <div>Question not found.</div>;
  }

  return (
    <>
      <Card extra="w-full h-full px-3 py-5 mt-5">
        <div className="mb-8 flex w-full items-center justify-between">
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            Question Details
          </h4>
        </div>

        {/* Question Statement */}
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Question</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {question.question_text}
          </p>
        </div>

        <h4 className="mt-6 px-2 text-xl font-bold text-navy-700 dark:text-white">
          Options
        </h4>
        <div className="grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 lg:grid-cols-3">
          {question.question_type === "text" ? (
            <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="mb-2 text-sm text-gray-600">Option Details</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                This is a text-type question with no options available.
              </p>
            </div>
          ) : (
            question.options?.map((option) => (
              <div
                key={option.id}
                className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none"
              >
                <p className="mb-2 text-sm text-gray-600">Option</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {option.option_text}
                </p>
                {question.question_type === "rating" && (
                  <p className="text-sm text-gray-500">
                    Rating: {option.rating}
                  </p>
                )}
              </div>
            ))
          )}
        </div>
      </Card>
    </>
  );
};

export default QuestionDetail;
