import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import TableHeader from 'components/TableHeader';
import TableCell from 'components/TableCell';
import Table from 'components/Table';
import { EmployeeLeaveInfoType } from 'types/leaves-types';
import { getEmployeeLeaveInfoByType } from 'api/apiHelper';
import { FaRegEye } from 'react-icons/fa';
import { toast } from 'react-toastify';

const LeaveInfoByType: React.FC = () => {
  const { leaveTypeId } = useParams<{ leaveTypeId: string }>();
  const [leaveInfoData, setLeaveInfoData] = useState<EmployeeLeaveInfoType[]>([]);
  const [leaveTypeDescription, setLeaveTypeDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const columnHelper = createColumnHelper<EmployeeLeaveInfoType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!leaveTypeId) {
          throw new Error('Leave Type ID is missing');
        }
        const response = await getEmployeeLeaveInfoByType(leaveTypeId);
        setLeaveInfoData(response.data.results);
        setLeaveTypeDescription(response.data.leave_type_description);
        console.log('Leave Info:', response.data);
      } catch (err: any) {
        console.error('Error fetching leave info:', err);
        setError('Failed to fetch leave info.');
        toast.error('Failed to fetch leave info.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [leaveTypeId]);

  const columns: ColumnDef<EmployeeLeaveInfoType, any>[] = [
    columnHelper.accessor('employee', {
      header: () => <TableHeader>Employee</TableHeader>,
      cell: (info) => {
        const employee = info.getValue();
        return (
          <TableCell>
            {employee.first_name} {employee.last_name}
          </TableCell>
        );
      },
    }),
    columnHelper.accessor('max_leaves_allowed', {
      header: () => <TableHeader>Total Leaves</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor((row) => row.max_leaves_allowed - row.remaining_leaves, {
      id: 'leaves_taken',
      header: () => <TableHeader>Leaves Taken</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor('remaining_leaves', {
      header: () => <TableHeader>Remaining Leaves</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor('employee', {
      id: 'view',
      header: () => <TableHeader>View</TableHeader>,
      cell: (info) => {
        const employee = info.getValue();
        const employeeId = employee.id;
        return (
          <TableCell>
            <Link to={`/employee/${employeeId}`} aria-label={`View Employee ${employeeId}`}>
              <FaRegEye className='h-6 w-6 text-blue-400 hover:text-blue-600 transition' />
            </Link>
          </TableCell>
        );
      },
    }),
  ];

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className='mt-5'>
      <Table<EmployeeLeaveInfoType>
        tableData={leaveInfoData}
        columns={columns}
        tableHeading={`Leave Information: ${leaveTypeDescription}`}
      />
    </div>
  );
};

export default LeaveInfoByType;
