import React, { useEffect, useState } from "react";
import { getFullAppraisalForm } from "api/apiHelper";
import {
  AppraisalFormDetailType,
} from "types/appraisals-types";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import AssignedEmployeesTable from "features/appraisals/AssignedEmployeesTable";
import { toast } from "react-toastify";

const FormDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [form, setForm] = useState<AppraisalFormDetailType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        if (!id) {
          throw new Error("Form ID is missing");
        }
        const res = await getFullAppraisalForm(id);
        setForm(res.data);
      } catch (error) {
        console.error("Failed to fetch form", error);
        toast.error("Failed to fetch form details.");
      } finally {
        setLoading(false);
      }
    };
    fetchForm();
  }, [id]);

  if (loading || !form) {
    return <div>Loading...</div>;
  }

  const createdAtDate = new Date(form.created_at).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <Card extra="w-full h-full px-3 py-5 mt-5">
        <div className="mb-8 flex w-full items-center justify-between">
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            Form Details
          </h4>
        </div>

        <div className="grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="mb-2 text-sm text-gray-600">Form Title</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {form.title}
            </p>
          </div>

          <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="mb-2 text-sm text-gray-600">Created by</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {form?.employee?.first_name} {form?.employee?.last_name}
            </p>
          </div>

          <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="mb-2 text-sm text-gray-600">Created At</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {createdAtDate}
            </p>
          </div>
        </div>

        <h4 className="mt-6 px-2 text-xl font-bold text-navy-700 dark:text-white">
          Questions
        </h4>
        {form.questions?.map((formQuestion) => {
          const { question, weightage } = formQuestion;
          return (
            <div
              key={question.id}
              className="mt-4 grid grid-cols-1 gap-4 px-2 sm:grid-cols-2"
            >
              <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="mb-2 text-sm text-gray-600">Question</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {question.question_text}
                </p>
                <p className="text-sm text-gray-500">
                  Weightage: {weightage}
                </p>
              </div>

              {/* Display options if they exist */}
              <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="mb-2 text-sm text-gray-600">Options</p>
                {question.question_type === "text" ? (
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    Type text, no options available
                  </p>
                ) : (
                  <ul className="list-disc pl-5">
                    {question.options?.map((option) => (
                      <li
                        key={option.id}
                        className="text-base font-medium text-navy-700 dark:text-white"
                      >
                        {option.option_text}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          );
        })}
      </Card>

      <AssignedEmployeesTable
        title="Assigned Employees"
        tableData={form.assigned_to || []}
      />
    </>
  );
};

export default FormDetail;
