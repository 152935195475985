import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { postAppraisalForm } from 'api/apiHelper';
import { toast } from 'react-toastify';
import { FullFormType, FormQuestionType, QuestionType, QuestionOptionType } from 'types/appraisals-types';

interface AppraisalFormComponentPropsType {
  formData: FullFormType | null;
}

interface FormSubmissionData {
  formId: number;
  answers: {
    [questionId: number]: {
      textAnswer?: string;
      selectedOptions?: number[];
    };
  };
}

const AppraisalFormComponent = (props: AppraisalFormComponentPropsType) => {
  const { formData } = props;
  const navigate = useNavigate();

  const [formSubmissionData, setFormSubmissionData] = useState<FormSubmissionData>({
    formId: formData?.id || 0,
    answers: {},
  });

  useEffect(() => {
    setFormSubmissionData({
      formId: formData?.id || 0,
      answers: {},
    });
  }, [formData]);

  const handleTextAnswerChange = (questionId: number, value: string) => {
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          textAnswer: value,
        },
      },
    }));
  };

  const handleSingleSelectChange = (questionId: number, optionId: number) => {
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          selectedOptions: [optionId],
        },
      },
    }));
  };

  const handleMultiSelectChange = (questionId: number, optionId: number, isChecked: boolean) => {
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          selectedOptions: isChecked
            ? [...(prevData.answers[questionId]?.selectedOptions || []), optionId]
            : (prevData.answers[questionId]?.selectedOptions || []).filter((id) => id !== optionId),
        },
      },
    }));
  };

  const handleRatingChange = (questionId: number, optionId: number) => {
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          selectedOptions: [optionId],
        },
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const notification = toast.loading('Submitting Form...');

    try {
      await postAppraisalForm(formSubmissionData);
      toast.update(notification, {
        render: 'Form submitted successfully',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });

      setTimeout(() => {
        navigate('/employee/appraisalforms');
      }, 500);

      handleReset();
    } catch (error) {
      console.log(error);
      toast.update(notification, {
        render: 'Failed to submit form',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const handleReset = () => {
    setFormSubmissionData({
      formId: formData?.id || 0,
      answers: {},
    });
  };

  const renderInputField = (question: QuestionType, index: number) => {
    switch (question.question_type) {
      case 'text':
        return (
          <InputField
            id={`text-${question.id}`}
            type="text"
            placeholder="Enter your answer here..."
            extra="w-full"
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleTextAnswerChange(question.id, e.target.value);
            }}
            value={formSubmissionData.answers[question.id]?.textAnswer || ''}
            required
          />
        );
      case 'select':
        return (
          <div className="mt-2">
            {question.options.map((option: QuestionOptionType, optionIndex: number) => (
              <div key={optionIndex} className="flex items-center gap-2 mb-2">
                <input
                  type="radio"
                  id={`select-${question.id}-${optionIndex}`}
                  name={`select-${question.id}`}
                  value={option.option_text}
                  onChange={() => {
                    handleSingleSelectChange(question.id, option.id);
                  }}
                />
                <label htmlFor={`select-${question.id}-${optionIndex}`}>{option.option_text}</label>
              </div>
            ))}
          </div>
        );
      case 'multi-select':
        return (
          <div className="mt-2">
            {question.options.map((option: QuestionOptionType, optionIndex: number) => (
              <div key={optionIndex} className="flex items-center gap-2 mb-2">
                <Checkbox
                  id={`multi-select-${question.id}-${optionIndex}`}
                  value={option.option_text}
                  onChange={(e) => handleMultiSelectChange(question.id, option.id, e.target.checked)}
                />
                <label htmlFor={`multi-select-${question.id}-${optionIndex}`}>{option.option_text}</label>
              </div>
            ))}
          </div>
        );
      case 'rating':
        return (
          <div className="mt-2">
            {question.options.map((option: QuestionOptionType, optionIndex: number) => (
              <div key={optionIndex} className="flex items-center gap-2 mb-2">
                <input
                  type="radio"
                  id={`rating-${question.id}-${optionIndex}`}
                  name={`rating-${question.id}`}
                  value={option.option_text}
                  onChange={() => {
                    handleRatingChange(question.id, option.id);
                  }}
                />
                <label htmlFor={`rating-${question.id}-${optionIndex}`}>{option.option_text}</label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card extra="w-full h-full pb-10 p-4">
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          {formData?.title}
        </h4>
        <p className="text-base text-navy-700 dark:text-white mt-4">
          {formData?.description}
        </p>
      </div>

      <form className="flex flex-col gap-6" onSubmit={handleSubmit} onReset={handleReset}>
        {formData?.questions.map((formQuestion: FormQuestionType, index: number) => (
          <div key={index} className="flex flex-col items-start gap-2">
            <span className="text-lg font-semibold">{`${index + 1}. ${formQuestion.question.question_text}`}</span>
            {renderInputField(formQuestion.question, index)}
          </div>
        ))}

        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">Cancel</Button>
          <Button type="submit" varient="primary" className="w-max">Submit</Button>
        </div>
      </form>
    </Card>
  );
};

export default AppraisalFormComponent;