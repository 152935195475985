import { useState, useEffect } from "react";
import ApplicantsTable from "./ApplicantsTable";
import { JobApplicantType } from "types/careers-types";
import { getAllApplicants } from "api/apiHelper";
import { toast } from "react-toastify";

const ApplicantsTab = () => {
  const [jobApplicants, setJobApplicants] = useState<JobApplicantType[]>([]);

  useEffect(() => {
    fetchApplicants();
  }, [])

  const fetchApplicants = async () => {
    try {
      const response = await getAllApplicants();
      setJobApplicants(response.data);
    } catch (error) {
      console.log(error)
      toast.error("Error fetching applicants.")
    }
  }

  return (
    <ApplicantsTable jobApplicants={jobApplicants} />
  )
};

export default ApplicantsTab;
