import React, { useEffect, useState } from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import TableHeader from 'components/TableHeader';
import TableCell from 'components/TableCell';
import Table from 'components/Table';
import { FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getLeaveTypes } from 'api/apiHelper';
import { LeaveType } from 'types/leaves-types';
import { toast } from 'react-toastify';

const LeaveTypesTable: React.FC = () => {
  const [leaveTypes, setLeaveTypes] = useState<LeaveType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const columnHelper = createColumnHelper<LeaveType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLeaveTypes();
        setLeaveTypes(response.data);
        console.log('Leave Types:', response.data);
      } catch (err: any) {
        console.error('Error fetching leave types:', err);
        setError('Failed to fetch leave types.');
        toast.error('Failed to fetch leave types.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns: ColumnDef<LeaveType, any>[] = [
    columnHelper.accessor('id', {
      header: () => <TableHeader>ID</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor('description', {
      header: () => <TableHeader>Leave Type</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.display({
      id: 'view',
      header: () => <TableHeader>View</TableHeader>,
      cell: (info) => (
        <TableCell>
          <Link to={`/leaves/leavetype/${info.row.original.id}/`} aria-label={`View Leave Type ${info.row.original.id}`}>
            <FaRegEye className='h-6 w-6 text-blue-400 hover:text-blue-600 transition' />
          </Link>
        </TableCell>
      ),
    }),
  ];

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <>
      <Table<LeaveType>
        tableData={leaveTypes}
        columns={columns}
        tableHeading="All Leave Types"
      />
    </>
  );
};

export default LeaveTypesTable;
