import React from "react";
import FormDetail from "features/appraisals/FormDetail";

// Admin Imports

import EmployeesLeaves from "pages/leaves/Leaves";
import EmployeeLeavesDetails from "pages/leaves/EmployeeLeavesDetails";
import LeaveDetail from "pages/leaves/LeaveDetail";
import CreateContract from "features/contracts/CreateContract";
import CreatePayslip from "pages/payslips/CreatePayslip";
import CreatePayslipBatch from "pages/payslips/CreatePayslipBatch";
import Payslip from "pages/payslips/Payslip";
import Payslips from "pages/payslips/Payslips";
import PayslipBatch from "pages/payslips/PayslipBatch";
import PayslipBatches from "pages/payslips/PayslipBatches";
import ResponseDetail from "features/appraisals/ResponseDetail";
import EmployeeAppraisals from "pages/appraisals/EmployeeAppraisals";
import EditAppraisalForm from "features/appraisals/EditAppraisalForm";
import CreateQuestion from "features/appraisals/CreateQuestion";
import EditQuestion from "features/appraisals/EditQuestion";
import QuestionDetail from "features/appraisals/QuestionDetail";
import CreateAppraisalForm from "features/appraisals/CreateAppraisalForm";

// Employee Imports
import Appraisals from "pages/appraisals/AdminAppraisals";
import AppraisalForm from "pages/appraisals/AppraisalsForm";
import BioData from "pages/employees/BioData";
import EmployeePayslips from "pages/payslips/EmployeePayslips";
import EmployeeBioData from "pages/employees/EmployeeBioData";
import JobDetails from "pages/carrers/JobDetails";
import Carrers from "pages/carrers/Careers";
import EditJobDetails from "pages/carrers/EditJobDetails";
import JobApplication from "pages/carrers/JobApplication";
import Applicant from "pages/carrers/Applicants";
import EditEmployeeBioData from "pages/employees/EditEmployeeBioData";
import LeaveInfoByType from "features/leaves/LeaveInfoByType";


// Auth Imports
import SignIn from "pages/auth/SignIn";

// Icon Imports
import {
  MdBarChart,
  MdLock,
  MdFeedback,
  MdDescription,
  MdPayment,
  MdOutlinePerson
} from "react-icons/md";

import PaySlipLineItemTypes from "pages/payslips/PayslipLineitemTypes";
import MyPayslips from "pages/payslips/MyPayslips";
import EmployeesList from "pages/employees/EmployeesList";
import EmployeesDetails from "pages/employees/EmployeeDetails";
import EmployeesProfile from "pages/employees/Profile";
import ShowContract from "pages/contracts/ShowContract";
import Home from "pages/auth/Home";

import MyContract from "pages/contracts/MyContract";

export const userRoutes: RoutesType[] = [
  {
    name: "Home",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/",
    display: false,
    permissions: ['employees.view_my_employee'],
    component: <Home />,
  },
  {
    name: "Profile",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/profile",
    display: true,
    permissions: ['employees.view_my_employee'],
    component: <EmployeesProfile />,
  },
  {
    name: "Employee List",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/all-employees",
    display: true,
    permissions: ['employees.view_employee'],
    component: <EmployeesList />,
  },
  {
    name: "Employee",
    layout: "Main",
    path: "/employee/:employeeId",
    display: false,
    permissions: ['employees.view_employee'],
    component: <EmployeesDetails />,
  },
  {
    name: "Edit Employee",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/employee/:employeeId/edit-bio-data",
    display: false,
    permissions:['employees.change_employee'],
    component: <EmployeeBioData />,
  },
  {
    name: "Careers",
    layout: "Main",
    path: "/careers",
    display: true,
    permissions:['careers.view_jobopening'],
    icon: <MdBarChart className="h-6 w-6" />,
    component: <Carrers />,
  },
  {
    name: "Job Description",
    layout: "Main",
    path: "/careers/job/:slug",
    display: false,
    permissions:['careers.view_jobopening'],
    component: <JobDetails />,
  },
  {
    name: "Edit Job Details",
    layout: "Main",
    path: "/careers/job/:slug/edit",
    display: false,
    permissions:['careers.view_jobopening'],
    component: <EditJobDetails />,
  },
  {
    name: "Job Application",
    layout: "Main",
    path: "/careers/job-application/:jobId",
    display: false,
    permissions:['careers.view_jobopening'],
    component: <JobApplication />,
  },
  {
    name: "Applicant Details",
    layout: "Main",
    path: "/careers/applicant/:applicantId",
    display: false,
    permissions:['careers.view_jobopening'],
    component: <Applicant />,
  },  
  {
    name: "Edit Profile",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/edit-profile",
    display: false,
    permissions:['employees.change_my_employee'],
    component: <EditEmployeeBioData />,
  },
  {
    name: "Create Contract",
    layout: "Main",
    path: "/create-contract",
    display: true,
    icon: <MdDescription className="h-6 w-6" />,
    permissions:["contracts.add_contract"],
    component: <CreateContract />,
  },
  {
    name: "Contract",
    layout: "Main",
    path: "/employee/contract/:contractId",
    display: false,
    permissions:["contracts.view_my_contract"],
    component: <ShowContract />,
  },
  {
    name: "My Contract",
    layout: "Main",
    path: "/my-contract",
    display: true,
    permissions:["contracts.view_my_contract"],
    component: <MyContract />,
  },
  {
    name: "Create Payslip",
    layout: "Main",
    path: "/payslip/create",
    permissions: ['payroll.add_payslip'],
    display: false,
    component: <CreatePayslip />,
  },
  {
    name: "Payslip",
    layout: "Main",
    path: "/payslip/:payslipId",
    permissions: ['payroll.view_payslip'],
    display: false,
    component: <Payslip />,
  },
  {
    name: "My Payslip",
    layout: "Main",
    path: "/my-payslip/:payslipId",
    permissions: ['payroll.view_my_payslip'],
    display: false,
    component: <Payslip />,
  },
  {
    name: "Payslips",
    layout: "Main",
    path: "/payslip",
    permissions: ['payroll.view_payslip'],
    display: true,
    icon: <MdPayment className="h-6 w-6" />,
    component: <Payslips />,
  },
  {
    name: "My Payslips",
    layout: "Main",
    path: "/my-payslip",
    permissions: ['payroll.view_my_payslip'],
    display: true,
    icon: <MdPayment className="h-6 w-6" />,
    component: <MyPayslips />,
  },
  {
    name: "Create Payslip Batch",
    layout: "Main",
    path: "/payslip/batch/create",
    permissions: ['payroll.add_payslipbatch'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <CreatePayslipBatch />,
  },
  {
    name: "Leaves Manage",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/employee/leaves",
    display: true,
    permissions:['leaves.view_employeeleavetype'],
    component: <EmployeesLeaves />,
  },
  {
    name: "Leave Detail",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/leaves/leavetype/:leaveTypeId/",
    display: false,
    permissions:['leaves.view_employeeleavesdetails'],
    component: <LeaveInfoByType />,
  },
  {
    name: "My Leaves Details",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/my-leaves/details/",
    display: true,
    permissions:["leaves.add_my_employeeleaveinfo"],
    component: <EmployeeLeavesDetails />,
  },
  {
    name: "Leave Details",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/leaves/:leaveId",
    display: false,
    permissions:['leaves.view_employeeleavesdetails'],
    component: <LeaveDetail />,
  },
  {
    name: "Leave Detail",
    layout: "Leave",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/leaves/leavedetails/:id/",
    display: false,
    permissions: ['leaves.view_employeeleavesdetails'],
    component: <LeaveInfoByType />,
  },
  {
    name: "Bio Data",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/bio-data",
    display: false,
    permissions:['employees.add_my_employee'],
    component: <BioData />,
  },
  {
    name: "Payslip Line Item Types",
    layout: "Main",
    path: "/payslip/line-item-types",
    permissions: ['payroll.view_paysliplineitemtype', 'payroll.add_paysliplineitemtype'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <PaySlipLineItemTypes />,
  },
  {
    name: "Payslip Batches",
    layout: "Main",
    path: "/payslip/batch",
    permissions: ['payroll.view_payslipbatch'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <PayslipBatches />,
  },
  {
    name: "Payslip Batch",
    layout: "Main",
    path: "/payslip/batch/:batchId",
    permissions: ['payroll.view_payslipbatch'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <PayslipBatch />,
  },
  {
    name: "Batch Payslip",
    layout: "Main",
    path: "/payslip/batch/:batchId/:payslipId",
    permissions: ['payroll.view_payslip'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <Payslip />,
  },
  {
    name: "Payslips Employee",
    layout: "Main",
    path: "/payslip/emp/:employeeId",
    permissions: ['payroll.view_payslip'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <EmployeePayslips />,
  },
  {
    name: "Response",
    layout: "Main",
    path: "/employee/appraisal/response/:id",
    display: false,
    permissions: [
      "appraisals.view_appraisalresponse",
      "appraisals.view_answer",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <ResponseDetail />,
  },
  {
    name: "Appraisals",
    layout: "Main",
    path: "/employee/appraisalforms",
    display: true,
    permissions:[
      "appraisals.add_appraisalresponse",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <EmployeeAppraisals />,
  },
  {
    name: "Appraisal Form",
    layout: "Main",
    path: "/employee/appraisalforms/form/:appraisalId",
    display: false,
    permissions:[
      "appraisals.add_appraisalresponse",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <AppraisalForm />,
  },
  {
    name: "Create Question",
    layout: "Main",
    path: "/appraisals/question/create",
    display: false,
    permissions: [
      "appraisals.add_question", 
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <CreateQuestion />,
  },
  {
    name: "Edit Question",
    layout: "Main",
    path: "/question/edit/:id",
    display: false,
    permissions: [
      "appraisals.add_question", 
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <EditQuestion />,
  },
  {
    name: "Appraisals",
    layout: "Main",
    path: "/employee/appraisal",
    display: true,
    permissions:[
      "appraisals.add_appraisalform",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <Appraisals />,
  },
  {
    name: "Form",
    layout: "Main",
    path: "/appraisals/form/:id",
    display: false,
    permissions: [
      "appraisals.add_appraisalform",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <FormDetail />,
  },

  //question detail
  {
    name: "Question",
    layout: "Main",
    path: "/appraisals/question/:id",
    display: false,
    permissions: [
      "appraisals.add_appraisalform",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <QuestionDetail />,
  },
  {
    name: "Form",
    layout: "Main",
    path: "/appraisals/form/edit/:id",
    display: false,
    permissions: [
      "appraisals.add_appraisalform",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <EditAppraisalForm />,
  },
  {
    name: "create Form",
    layout: "Main",
    path: "/appraisals/form/create",
    display: false,
    permissions: [
      "appraisals.add_appraisalform",
    ],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <CreateAppraisalForm />,
  },
];

export const authRoutes: RoutesType[] = [
  {
    name: "Sign In",
    layout: "Minimal",
    path: "/auth/sign-in",
    display: false, 
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
]

export const allRoutes: RoutesType[] = [...authRoutes,...userRoutes] ;