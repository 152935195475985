import { useState, useEffect } from 'react';
import AssignedFormTable from 'features/appraisals/AssignedFormsTable';

import {
  getAssignedFormsForUser,
} from 'api/apiHelper';


export interface FormType {
  id: number;
  title: string;
  description: string;
  created_by: number;
  created_at: string;
  updated_at: string;
}

const EmployeeAppraisals = () => {
  const [assignedForms, setAssignedForms] = useState<FormType[]>([]);


  const fetchAssignedForms = async () => {
    try {
      const response = await getAssignedFormsForUser();
      setAssignedForms(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignedForms();
    
  }, []);

  return (
    <div className="mt-5">  
      <AssignedFormTable title="Assigned Forms" tableData={assignedForms} />
    </div>
  );
};

export default EmployeeAppraisals;