import { useState } from 'react';
import InputField from 'components/InputField';
import Card from 'components/Card';
import Button from 'components/Button';
import { postSkill } from 'api/apiHelper';
import { toast } from 'react-toastify';

interface SkillType {
  id: string;
  name: string;
};

const SkillsForm = () => {
  const [skillName, setSkillName] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSkillName(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newSkill: SkillType = {
      id: '',
      name: skillName,
    };

    try {
      await postSkill(newSkill);
      toast.success("Skill added successfully");
      handleReset();
    } catch (error) {
      console.log(error);
      toast.error("Failed to add skill");
    }
  };

  const handleReset = () => {
    setSkillName('');
  };

  return (
    <Card extra="w-full h-full pb-10 p-4">
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">Add Skill</h4>
      </div>

      <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit} onReset={handleReset}>
        <InputField
          id="skillName"
          label="Skill Name"
          type="text"
          placeholder="Enter skill name"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={skillName}
        />
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">Cancel</Button>
          <Button type="submit" varient="primary" className="w-max">Submit</Button>
        </div>
      </form>
    </Card>
  );
};

export default SkillsForm;