import { useState, useEffect } from "react";
import Card from "components/Card";
import InputField from "components/InputField";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import SelectAllButton from "components/SelectAllButton";
import { getAllEmployees, getLeaveTypes, postBulkLeaveInfo } from "api/apiHelper";
import { LeaveInfoType } from "features/leaves/RegisterLeaveTypeForm";
import { toast } from "react-toastify";
import { AssignMaxLeavesType } from "types/leaves-types";

interface EmployeeType {
  id: string;
  first_name: string;
  last_name: string;
}

const AssignMaxLeavesForm = () => {
  const [employeesData, setEmployeesData] = useState<EmployeeType[]>([]);
  const [leaveTypesData, setLeaveTypesData] = useState<LeaveInfoType[]>([]);
  const [assignMaxLeavesData, setAssignMaxLeavesData] =
    useState<AssignMaxLeavesType>({
      employees: [],
      leave_types: [],
      max_leaves_allowed: "",
      period_start: "",
      period_end: "",
    });
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [selectedLeaveTypeIds, setSelectedLeaveTypeIds] = useState<string[]>([]);
  const [selectAllEmployees, setSelectAllEmployees] = useState(false);
  const [selectAllLeaveTypes, setSelectAllLeaveTypes] = useState(false);

  const fetchEmployees = async () => {
    try {
      const response = await getAllEmployees();
      setEmployeesData(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch employees.");
    }
  };

  const fetchLeaveTypes = async () => {
    try {
      const response = await getLeaveTypes();
      setLeaveTypesData(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch leave types.");
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchLeaveTypes();
  }, []);

  const handleEmployeeCheckboxChange = (employeeId: string) => {
    setSelectedEmployeeIds((prevSelected) => {
      if (prevSelected.includes(employeeId)) {
        return prevSelected.filter((id) => id !== employeeId);
      } else {
        return [...prevSelected, employeeId];
      }
    });
  };

  const handleLeaveTypeCheckboxChange = (leaveTypeId: string) => {
    setSelectedLeaveTypeIds((prevSelected) => {
      if (prevSelected.includes(leaveTypeId)) {
        return prevSelected.filter((id) => id !== leaveTypeId);
      } else {
        return [...prevSelected, leaveTypeId];
      }
    });
  };

  const handleSelectAllEmployees = () => {
    if (selectAllEmployees) {
      setSelectedEmployeeIds([]);
    } else {
      setSelectedEmployeeIds(employeesData.map((employee) => employee.id));
    }
    setSelectAllEmployees(!selectAllEmployees);
  };

  const handleSelectAllLeaveTypes = () => {
    if (selectAllLeaveTypes) {
      setSelectedLeaveTypeIds([]);
    } else {
      setSelectedLeaveTypeIds(
        leaveTypesData.map((leave) => leave.id.toString())
      );
    }
    setSelectAllLeaveTypes(!selectAllLeaveTypes);
  };

  useEffect(() => {
    if (
      selectedEmployeeIds.length === employeesData.length &&
      employeesData.length > 0
    ) {
      setSelectAllEmployees(true);
    } else {
      setSelectAllEmployees(false);
    }
  }, [selectedEmployeeIds, employeesData]);

  useEffect(() => {
    if (
      selectedLeaveTypeIds.length === leaveTypesData.length &&
      leaveTypesData.length > 0
    ) {
      setSelectAllLeaveTypes(true);
    } else {
      setSelectAllLeaveTypes(false);
    }
  }, [selectedLeaveTypeIds, leaveTypesData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setAssignMaxLeavesData({
      ...assignMaxLeavesData,
      [id]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedEmployeeIds.length === 0) {
      toast.warning("Please select at least one employee.");
      return;
    }

    if (selectedLeaveTypeIds.length === 0) {
      toast.warning("Please select at least one leave type.");
      return;
    }

    if (
      !assignMaxLeavesData.max_leaves_allowed ||
      !assignMaxLeavesData.period_start ||
      !assignMaxLeavesData.period_end
    ) {
      toast.warning("Please fill in all the required fields.");
      return;
    }

    const notification = toast.loading("Assigning Max Leaves...");

    try {
      const payload = {
        employees: selectedEmployeeIds,
        leave_types: selectedLeaveTypeIds,
        max_leaves_allowed: assignMaxLeavesData.max_leaves_allowed,
        period_start: assignMaxLeavesData.period_start,
        period_end: assignMaxLeavesData.period_end,
      };

      const response = await postBulkLeaveInfo(payload);
      console.log(response);

      toast.update(notification, {
        render: "Leaves assigned successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      handleReset();
    } catch (error: any) {
      console.log(error);

      let errorMessage = "Failed to assign Max Leaves";

      if (error.response && error.response.data) {
        if (Array.isArray(error.response.data)) {
          errorMessage = error.response.data.join("\n");
        } else if (typeof error.response.data === "object") {
          errorMessage = Object.values(error.response.data).join("\n");
        } else {
          errorMessage = error.response.data.toString();
        }
      }

      toast.update(notification, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleReset = () => {
    setAssignMaxLeavesData({
      employees: [],
      leave_types: [],
      max_leaves_allowed: "",
      period_start: "",
      period_end: "",
    });
    setSelectedEmployeeIds([]);
    setSelectedLeaveTypeIds([]);
    setSelectAllEmployees(false);
    setSelectAllLeaveTypes(false);
  };

  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Assign Max Leaves
        </h4>
      </div>

      {/* Form */}
      <form
        className="flex w-full flex-col gap-6"
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {/* Max Leaves Allowed */}
        <InputField
          id="max_leaves_allowed"
          label="Max Leaves Allowed"
          type="number"
          placeholder="Enter max leaves allowed"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={assignMaxLeavesData.max_leaves_allowed}
          required
        />

        {/* Period Start */}
        <InputField
          id="period_start"
          label="Period Start"
          type="date"
          placeholder="Enter start date"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={assignMaxLeavesData.period_start}
          required
        />

        {/* Period End */}
        <InputField
          id="period_end"
          label="Period End"
          type="date"
          placeholder="Enter end date"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={assignMaxLeavesData.period_end}
          required
        />

        {/* Select Employees */}
        <div>
          <div className="flex justify-between items-center">
            <label className="text-sm font-bold text-gray-600 dark:text-white">
              Select Employees
            </label>
            <SelectAllButton
              isAllSelected={selectAllEmployees}
              onClick={handleSelectAllEmployees}
            />
          </div>
          <hr className="mt-2 mb-3" />
          <div className="border-gray-300 dark:border-gray-700 rounded p-2 max-h-60 overflow-y-auto">
            {employeesData.map((employee) => (
              <div key={employee.id} className="flex items-center mb-2">
                <Checkbox
                  checked={selectedEmployeeIds.includes(employee.id)}
                  onChange={() => handleEmployeeCheckboxChange(employee.id)}
                  extra="mr-2"
                  color="blue"
                />
                <label className="text-sm font-medium text-navy-700 dark:text-white">
                  {employee.first_name} {employee.last_name}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Select Leave Types */}
        <div>
          <div className="flex justify-between items-center">
            <label className="text-sm font-bold text-gray-600 dark:text-white">
              Select Leave Types
            </label>
            <SelectAllButton
              isAllSelected={selectAllLeaveTypes}
              onClick={handleSelectAllLeaveTypes}
            />
          </div>
          <hr className="mt-2 mb-3" />
          <div className="border-gray-300 dark:border-gray-700 rounded p-2 max-h-60 overflow-y-auto">
            {leaveTypesData.map((leave) => (
              <div key={leave.id} className="flex items-center mb-2">
                <Checkbox
                  checked={selectedLeaveTypeIds.includes(leave.id.toString())}
                  onChange={() =>
                    handleLeaveTypeCheckboxChange(leave.id.toString())
                  }
                  extra="mr-2"
                  color="blue"
                />
                <label className="text-sm font-medium text-navy-700 dark:text-white">
                  {leave.description}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Submit and Cancel Buttons */}
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">
            Cancel
          </Button>
          <Button type="submit" varient="primary" className="w-max">
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default AssignMaxLeavesForm;
