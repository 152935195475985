import config from "config";

export const BASE_URL = config.app.BACKEND_URL;

// Auth Urls
export const LOGIN_URL = "/auth/api-token-auth/";
export const GOOGLE_REDIRECT = "/auth/sign-in";
export const GOOGLE_LOGIN_URL = "/auth/google-login/"

// Contracts Urls
export const GET_CLAUSES_URL = "/contracts/clauses/"
export const CONTRACTS_URL = "/contracts/contracts/"
export const MY_CONTRACT_URL = "/contracts/contracts/my_contract/"

// Employees Urls
export const GET_ALL_EMPLOYEES = "/employees/employees/";
export const EMPLOYEES_URL = "/employees/employees/";
export const EMPLOYEE_PROFILE_URL = "employees/profile/"
export const CREATE_EMPLOYEE_BY_USER = "/employees/employees/create_by_user/";
export const UPDATE_EMPLOYEE_BY_USER = "/employees/employees/update_by_user/";
export const GET_ALL_BANKS = "/employees/banks/";

// Dependents Urls
export const DEPENDENTS_BULK_CREATE = "/employees/dependants/bulk_create/";

// Employees Leaves Urls
export const GET_EMPLOYEE_LEAVES_INFO = "/leaves/leaveinfo/employee/";
export const GET_EMPLOYEE_LEAVES_DETAILS = "/leaves/leavedetails/employee/";
export const GET_PENDING_LEAVES_DETAILS = "/leaves/leavedetails/pending/"
export const GET_APPROVED_LEAVES_DETAILS = "/leaves/leavedetails/approved/"
export const GET_DECLILNE_LEAVES_DETAILS = "/leaves/leavedetails/declined/"
export const REMAINING_LEAVES_TYPES_INFO = "/leaves/leaveinfo/remaining/employee/";
export const LEAVE_DETAILS = "/leaves/leavedetails/";
export const REQUEST_LEAVE = "/leaves/leavedetails/request/";
export const LEAVE_TYPE = "/leaves/leavetype/";
export const LEAVE_INFO = "/leaves/leaveinfo/";
export const ALL_LEAVES_INFO = "/leaves/allleaves";
export const GET_ALL_LEAVES_DETAILS = "/leaves/leavedetails/";
// api/urls.ts


// PAYROLL URLS
export const PAYSLIP_NON_AUTO_ITEM_TYPES_URL = "/payroll/non-auto-line-item-types/";
export const PAYSLIP_URL = "/payroll/payslip/";
export const PAYSLIP_PDF_URL =  "/payroll/generate-pdf/"
export const PAYSLIP_BATCH_URL = "/payroll/batch-payslips/";
export const PAYSLIPS_EMPLOYEE_URL = "/payroll/employee/";
export const PAYSLIP_LINE_ITEM_TYPES_URL = "/payroll/payslip-item-type/";
export const MY_PAYSLIPS_URL = "/payroll/my-payslips/";
export const PAYROLL_REPORTS_URL = "/payroll/reports/generate_reports/";
export const PAYROLL_TAX_REPORT_URL = "/payroll/reports/generate_tax_report/";

// Appraisalsl Urls
export const GET_ASSIGNED_FORMS_FOR_USER = "/appraisals/appraisal_forms/assigned-forms-for-user/";
export const GET_COMPLETED_FORMS_FOR_USER = "/appraisals/appraisal_forms/completed-forms-for-user/";
export const GET_APPRAISAL_FORM = "/appraisals/appraisal_forms/";
export const POST_APPRAISAL_FORM = "/appraisals/appraisal_forms/submit/";
export const QUESTIONS_URL = '/appraisals/questions/';
export const APPRAISAL_RESPONSES_URL = '/appraisals/appraisal_responses/';
export const ASSIGN_FORMS_URL = "/appraisals/appraisal_forms/assign/";
export const UPDATE_FORM_URL = "/appraisals/appraisal_forms/";
export const DELETE_APPRAISAL_FORM_URL = "/appraisals/appraisal_forms/";
export const SET_FORM_STATUS_URL = "/appraisals/appraisal_forms/";
export const GET_ACTIVE_APPRAISAL_FORMS_URL = "/appraisals/appraisal_forms/active/";
export const GET_INACTIVE_APPRAISAL_FORMS_URL = "/appraisals/appraisal_forms/inactive/";


// Careers Urls
export const GET_ALL_JOB_OPENINGS = "/careers/job-openings/"
export const GET_ALL_APPLICANTS = "/careers/applicants/"
export const GET_ALL_JOB_APPLICATIONS = "/careers/job-applications/"
export const GET_STATUS_CHOICES = "careers/job-applications/status-choices/"
export const GET_SKILLS = "/careers/skills/"
export const POST_SKILLS = "/careers/skills/"
export const DEPARTMENTS_URL = "/careers/departments/"
