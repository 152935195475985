import { useState, useEffect } from 'react';
import { getPendingLeavesDetails, getApprovedLeavesDetails, getDeclinedLeavesDetails } from 'api/apiHelper';
import TabsContainer from "components/TabsContainer";
import Tab from "components/Tab"; 
import PendingLeavesTable from 'features/leaves/PendingLeavesTable';
import ApprovedLeavesTable from 'features/leaves/ApprovedLeavesTable';
import DeclinedLeavesTable from 'features/leaves/DeclinedLeavesTable';
import AssignMaxLeavesForm from 'features/leaves/AssignMaxLeavesForm';
import RegisterLeaveTypeForm from 'features/leaves/RegisterLeaveTypeForm';
import ErrorBox from 'components/ErrorBox';
import { usePermissions } from 'app/hooks';
import LeaveTypesTable from "features/leaves/LeavesTypesTable";

export interface LeaveDetailsType {
  id: number;
  employee_id: number;
  employee_first_name: string;
  employee_last_name: string;
  start_date: string;   
  end_date: string; 
  leave_status: "P" | "A" | "D"; 
  reason: string | null; 
  comments: string | null;
  leave_info: number;
  leave_type_description: string;
}

const EmployeesLeaves = () => {
  const [pendingLeaves, setPendingLeaves] = useState<LeaveDetailsType[] | []>([]);
  const [approvedLeaves, setApprovedLeaves] = useState<LeaveDetailsType[] | []>([]);
  const [declinedLeaves, setDeclinedLeaves] = useState<LeaveDetailsType[] | []>([]);
  const permissions = usePermissions() || [];

  const fetchPendingLeaves = async () => {
    try {
      const response = await getPendingLeavesDetails()
      setPendingLeaves(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchApprovedLeaves = async () => {
    try {
      const response = await getApprovedLeavesDetails()
      setApprovedLeaves(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchDeclinedLeaves = async () => {
    try {
      const response = await getDeclinedLeavesDetails()
      setDeclinedLeaves(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPendingLeaves()
    fetchApprovedLeaves()
    fetchDeclinedLeaves()
  }, []);

  return (
    <div className="mt-5">
      <TabsContainer>
        
        <Tab isActive={true} title="Pending" key={1}>
          <PendingLeavesTable tableData={pendingLeaves} tableHeading="Pending Leaves" />
        </Tab>
        <Tab isActive={false}  title="Approved" key={2}>
          <ApprovedLeavesTable key={2} tableData={approvedLeaves} tableHeading="Approved Leaves" />
        </Tab>
        <Tab isActive={false}  title="Declined" key={3}>
          <DeclinedLeavesTable key={3} tableData={declinedLeaves} tableHeading="Declined Leaves" />
        </Tab>
        <Tab isActive={false}  title="Register Type" key={4}>
          {permissions && permissions.includes('leaves.add_employeeleavetype')?
            <RegisterLeaveTypeForm />
            : <ErrorBox errorMessage="Insufficient privileges."/>
          }
        </Tab>
        <Tab isActive={false}  title="All Types" key={4}>
          {permissions && permissions.includes('leaves.add_employeeleavetype')?
            <LeaveTypesTable />
            : <ErrorBox errorMessage="Insufficient privileges."/>
          }
        </Tab>
        <Tab isActive={false}  title="Assign" key={5}>
          {permissions && permissions.includes('employees.view_employee') && permissions.includes('leaves.view_employeeleavetype') && permissions.includes('leaves.add_employeeleaveinfo')?
            <AssignMaxLeavesForm />
            : <ErrorBox errorMessage="Insufficient privileges."/>
          }
        </Tab>
      </TabsContainer>
    </div>
  );
};

export default EmployeesLeaves;
